<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>设置管理</el-breadcrumb-item>
        <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">反馈人手机号：</div>
                <el-input v-model="search.feedbackPhone" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">反馈类型：</div>
                <el-select v-model="search.feedbackType" placeholder="请选择">
                  <el-option
                      v-for="item in feedbackType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">处理状态：</div>
                <el-select v-model="search.feedbackStatus" placeholder="请选择">
                  <el-option label="待处理" :value="0"></el-option>
                  <el-option label="已处理" :value="1"></el-option>
                </el-select>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="createUserName" label="反馈人昵称"></el-table-column>
            <el-table-column align="center" prop="feedbackPhone" label="反馈人手机号"></el-table-column>
            <el-table-column align="center" label="反馈类型">
              <template slot-scope="scope">
                {{ scope.row.feedbackType | getFeedbackType }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="feedbackContent" label="反馈内容" min-width="200"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" label="反馈图片" min-width="200">
              <template slot-scope="scope">
                <div v-if="scope.row.feedbackImgs">
                <img class="icon-img"
                     v-for="(item,index) in scope.row.feedbackImgs"
                     :key="index"
                     @click="handleSee(item)"
                     :src="item"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createDate" label="反馈时间"></el-table-column>
            <el-table-column align="center" label="处理状态" min-width="60">
              <template slot-scope="scope">
                {{ scope.row.feedbackStatus == 0 ? "待处理" : "已处理" }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人" min-width="60"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人" min-width="60"></el-table-column>
            <el-table-column align="center" label="操作" min-width="120">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <!--                  <div class="border_center_left" v-if="categoriesInfoUpd" @click="edit(scope.row)">编辑</div>-->
                  <div class="border_center_left" v-if="feedbackInfoUpd && scope.row.feedbackStatus === 0"
                       @click="changeStatus(scope.row)">处理
                  </div>
                  <div class="border_center_left" v-if="feedbackInfoDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" :model="upData" :rules="rules" ref="ruleForm" label-position="right"
               label-width="140px">
        <el-form-item label="反馈人姓名：">{{ upData.createUser }}</el-form-item>
        <el-form-item label="反馈人手机号：">{{ upData.feedbackPhone }}</el-form-item>
        <el-form-item label="反馈类型：">{{ upData.feedbackType | getFeedbackType }}</el-form-item>
        <el-form-item label="处理状态：">
          <el-tag type="danger" v-if="upData.feedbackStatus == 0">待处理</el-tag>
          <el-tag v-if="upData.feedbackStatus == 1">已处理</el-tag>
        </el-form-item>
        <el-form-item label="反馈图片：" prop="advertiseImage">
          <div class="flexRow">
            <div class="up-image">
              <div class="image-list flexRow">
                <img v-for="(item, index) in upData.feedbackImgs" :key="index" @click="handleSee(item)" :src="item" alt/>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>

      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="edit" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    return {
      search: {
        feedbackPhone: "",
        feedbackType: null,
        feedbackStatus: null,
      },
      type: "",
      feedbackType: [
        {label: '商品相关', value: 1},
        {label: '订单支付', value: 2},
        {label: '账户积分', value: 3},
        {label: '优惠活动', value: 4},
        {label: '产品建议', value: 5},
        {label: '其他', value: 6},
      ],
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      formDataReady: true,
      tableLoading: false,
      btnLoading: false,
      dialogImgUrl: "",
      upData: {
        advertiseImage: "",
        categoriesName: "",
        sort: 0,
        sourceId: null,
        updateDate: "",
        updateUser: ""
      },
      rules: {
        categoriesName: [{required: true, message: "请输入分类名称", trigger: "blur"}],
        advertiseImage: [{required: true, message: "请上传广告图片", trigger: "blur"}],
        sourceId: [{required: true, message: "请选择来源类型", trigger: "change"}]
      },
      feedbackInfoDel: false,
      feedbackInfoUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  // 过滤器需要写在组件之上
  filters: {
    getFeedbackType(value) {
      // _this 在全局设置 let _this, 在data 里面赋值 _this: this;
      const data = _this.feedbackType.filter(item => item.value == value);
      // 确保有值
      if (data && data.length > 0) {
        return data[0].label;
      }
    }
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  watch: {
    type: function (val) {
      // console.log(val)
    },
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      // _this.tableList.push({
      //   createDate: "2021-08-26 14:00:00",
      //   createUser: "admin",
      //   feedbackContent: "意见反馈一般首先描述一下大致情况，然后是具体意见。意见分点逐条写比较明确，可以先逐条写缺陷和方法写在一起。",
      //   feedbackImgs: "https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png,https://rambo2.oss-cn-beijing.aliyuncs.com/rambo2/2021826/1629944275139.png",
      //   feedbackPhone: "15681776845",
      //   feedbackStatus: 0,
      //   feedbackType: 1,
      //   id: "1",
      //   updateDate: "",
      //   updateUser: ""
      // });
      // _this.tableList.forEach(item => {
      //   item.feedbackImgs = item.feedbackImgs.split(',');
      // });
      // return false;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for(let key in filterData) {
        if(filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }
      if(filterData.feedbackStatus === 0) {
        requestData.search.feedbackStatus = 0;
      }
      _this.$api
          .getFeedbackInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableList.forEach(item => {
                item.feedbackImgs = item.feedbackImgs.split(',');
              });
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "feedbackInfo:del") {
          _this.feedbackInfoDel = true;
        }
        if (v == "feedbackInfo:upd") {
          _this.feedbackInfoUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    edit() {
      let _this = this;
      _this.tableLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.$api.editFeedbackInfo(_this.upData).then(res => {
            if (res.data.code == 200) {
              _this.tableLoading = false;
              _this.$message.success("修改成功");
              _this.getData();
              _this.handleClose();
            }
          });
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        categoriesName: "",
        advertiseImage: "",
        sourceId: null,
        optional: 0,
        sort: 0,
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        feedbackPhone: "",
        feedbackType: null,
        feedbackStatus: null
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      // _this.upData.feedbackImgs = _this.upData.feedbackImgs.split(',');
    },
    // edit(e) {
    //   let _this = this;
    //   _this.dialogVisible = true;
    //   _this.type = 'edit';
    //   _this.upData = JSON.parse(JSON.stringify(e));
    //   _this.upList = [];
    //   _this.upList.push({
    //     url: _this.upData.advertiseImage
    //   });
    // },
    changeStatus(e) {
      let _this = this;
      _this.$confirm('确定已处理该意见反馈, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.disposeFeedbackInfo({id: e.id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('处理成功');
                  _this.getData();
                } else {
                  _this.$message.error('处理失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delFeedbackInfo({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.advertiseImage = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.advertiseImage = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.advertiseImage = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}
</style>
